<template>
  <div>
    <header-nav :bg="bg" :text="text" :index="2"/>
    <div class="service-wrap">
      <a class="service" v-for="(item, index) in goods" v-bind:key="index"  :href="item.href" target="_blank">
        <img class="cover" :src="item.img"/>
        <div class="content-wrap">
          <div class="title">{{item.title}}</div>
          <div class="content">{{item.content}}</div>
        </div>
      </a>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderNav from '../../components/HeaderNav'
import Footer from '../../components/Footer'
import bg from '../../assets/header-bg2.png'
import good1 from '../../assets/good-detail1.png'
import good2 from '../../assets/good-detail2.png'
import good3 from '../../assets/good-detail3.png'
import good4 from '../../assets/good-detail4.png'

export default {
  components: { HeaderNav, Footer },
  data() {
    return {
      bg,
      text: '服务内容',
      goods: [{
        title: '小白英语-复试口语模板课',
        content: '考研复试大致分为：英语口语复试+专业面试+笔试等三部分，1门课程，5大模块，50+模板，一站式解决「英语复试」难题',
        img: good1,
        href: 'https://detail.youzan.com/show/goods?alias=2g0bww4ztwj9h'
      }, {
        title: '小白英语-全年锦鲤计划',
        content: '完整科学的英语复习备考计划，包含6大题型，让指示模块不留死角；轻课授课模式，课时更短、干货更多；从基础到冲刺，覆盖英语复习全周期',
        img: good2,
        href: 'https://appGeAd5iob7930.h5.xiaoeknow.com/content_page/eyJ0eXBlIjoiMyIsInJlc291cmNlX3R5cGUiOiI4IiwicmVzb3VyY2VfaWQiOiIiLCJwcm9kdWN0X2lkIjoicF81ZWVjOWYxYTkwYTA5X3pQQTRtV2NtIiwiYXBwX2lkIjoiYXBwR2VBZDVpb2I3OTMwIiwic2hhcmVfdXNlcl9pZCI6InVfNWRhNmM3OGNlNTJiOF9OdUJ4bkplenk5Iiwic2hhcmVfdHlwZSI6NSwic2NlbmUiOiJcdTkwODBcdThiZjdcdTk0ZmVcdTYzYTUifQ'
      }, {
        title: '小白政治-全年锦鲤计划',
        content: '王牌课程的集合之作，从知识点到刷题到押题，从纸质版讲义到课程到电子版讲义，全方位、多层次、无死角解决政治备考的全部问题，全课程包含15个模块，覆盖政治复习全周期',
        img: good3,
        href: 'https://appGeAd5iob7930.h5.xiaoeknow.com/content_page/eyJ0eXBlIjoiMyIsInJlc291cmNlX3R5cGUiOiIyNSIsInJlc291cmNlX2lkIjoiIiwicHJvZHVjdF9pZCI6InRlcm1fNWVlNzRkMDJkMTM2OV9reFRtS3IiLCJhcHBfaWQiOiJhcHBHZUFkNWlvYjc5MzAiLCJzaGFyZV91c2VyX2lkIjoidV81ZGE2Yzc4Y2U1MmI4X051QnhuSmV6eTkiLCJzaGFyZV90eXBlIjo1LCJzY2VuZSI6Ilx1OTA4MFx1OGJmN1x1OTRmZVx1NjNhNSJ9'
      }, {
        title: '小白专业课-199管理类综合（统考）',
        content: '课程包含管理类综合全部三大模块：逻辑、数学、写作，拆解23年真题解题技巧，体型归纳分析，知识点讲解，是管综复习备考必看神器。',
        img: good4,
        href: 'https://shop43487693.m.youzan.com/wscgoods/detail/360hd1bowfaf9'
      }]
    }
  }
}
</script>

<style lang="less" scoped>
  .service-wrap{
    padding-top: 143px;
    padding-bottom: 120px;
    background: #FBFBFB;
    .service{
      display: flex;
      align-items: center;
      width: 720px;
      margin: 0 auto;
      margin-bottom: 40px;
      background: #ffffff;
      text-decoration: none;
      &:last-child{
        margin-bottom: 0;
      }
      .content-wrap{
        padding: 0 22px 0 30px;
        box-sizing: border-box;
        .title{
          color: #333333;
          font-size: 20px;
        }
        .content{
          margin-top: 14px;
          color:#595959;
          font-size: 12px;
        }
      }
    }
  }
  @media screen and (max-width:500px){
    .service-wrap{
      padding: 48px 0;
      .service{
        width: 100%;
        padding: 20px 12px;
        margin-bottom: 12px;
        box-sizing: border-box;
        .cover{
          width: 102px;
          height: 76px;
        }
        .content-wrap{
          flex: 1;
          padding: 0 12px;
        }
      }
    }
  }
</style>